import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { ImageType } from "../types/images";

type Props = {
  image: ImageType;
  alt: string;
  title?: string;
  className?: string;
};

export const Image: React.FC<Props> = ({ image, ...rest }) => {
  if (!image) return null;
  if (typeof image === "string") return <img src={image} {...rest} />;

  const gatsbyImage = image.childImageSharp?.gatsbyImageData;
  if (!gatsbyImage) return null;
  return <GatsbyImage image={gatsbyImage} {...rest} />;
};
