import React from "react";
import { Layout } from "../components/Layout";
import { graphql } from "gatsby";
import { Seo } from "../components/Seo";
import { NextPrev } from "../components/NextPrev";
import { MdxRenderer } from "../components/Mdx/MdxRenderer";
import { BlogPost } from "../components/Blog/BlogPost";
import { getBlogPostUrl } from "../utils/urls";
import { MdxImageSharp } from "../types/images";
import { MdxHeading, MdxListPageContext } from "../types/mdx";
import { PageProps } from "gatsby";
import { Container } from "../components/Section";

type MdxBlogPostData = {
  mdx?: {
    id: string;
    excerpt: string;
    body: string;
    frontmatter: {
      title: string;
      date: string;
      author: string;
      description: string;
      featured: MdxImageSharp;
      ogImage: MdxImageSharp;
      tags: string[];
    };
    fields: {
      readingTime: {
        text: string;
      };
    };
    headings: MdxHeading[];
  };
};

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { collection: { eq: "blog" }, slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        description
        tags
        featured {
          ...PageFeaturedData
        }
        ogImage: featured {
          ...OgImageData
        }
      }
      fields {
        readingTime {
          text
        }
      }
      headings {
        depth
        value
      }
    }
  }
`;

const BlogPostTemplate: React.FC<
  PageProps<MdxBlogPostData, MdxListPageContext>
> = ({ data, pageContext }) => {
  const post = data.mdx;
  if (!post || !post.frontmatter || !post.fields) return null;

  const { frontmatter, fields } = post;

  const { prev, next, slug } = pageContext;

  return (
    <Layout>
      <Seo
        article
        pureTitle
        title={frontmatter.title}
        description={frontmatter.description || post.excerpt}
        ogImage={frontmatter.ogImage}
        maxImagePreview="large"
      />
      <BlogPost
        slug={slug}
        author={frontmatter.author}
        title={frontmatter.title}
        description={frontmatter.description}
        date={frontmatter.date}
        readingTime={fields.readingTime?.text}
        featured={frontmatter.featured}
        tags={frontmatter.tags}
      >
        <MdxRenderer headings={post.headings}>{post.body}</MdxRenderer>
      </BlogPost>

      <Container>
        <NextPrev
          prev={
            prev
              ? { to: getBlogPostUrl(prev.slug), title: prev.title }
              : undefined
          }
          next={
            next
              ? { to: getBlogPostUrl(next.slug), title: next.title }
              : undefined
          }
        />
      </Container>
    </Layout>
  );
};

export default BlogPostTemplate;
