import "gatsby-remark-vscode/styles.css";

import React from "react";
import { ShareButtons } from "../ShareButtons";
import { PageFeatured } from "../PageFeatured";
import { getBlogPostUrl } from "../../utils/urls";
import { ImageType } from "../../types/images";
import { Article, ArticleContent, ArticleHeader } from "../Article";
import { Container } from "../Section";
import { Image } from "../Image";
import * as styles from "./BlogPost.module.scss";

type Props = {
  slug: string;
  author?: string;
  title: string;
  description?: string;
  date: string;
  readingTime: string;
  featured: ImageType;
  tags: string[];
};

export const BlogPost: React.FC<Props> = ({
  slug,
  author,
  title,
  description,
  date,
  readingTime,
  children,
  featured,
  tags,
}) => {
  // remove dates temporarily
  date = "";
  // {[date, author, readingTime].filter(Boolean).join(" • ")}

  return (
    <Container>
      <PageFeatured>
        <div className={styles.topImageWrapper}>
          <Image image={featured} alt="Featured Image" />
        </div>
      </PageFeatured>
      <Article>
        <div className={styles.pageHeaderWrapper}>
          <div className={styles.topInfoRow}>
            <div className={styles.tags}>
              {tags.map((tag) => (
                <div key={tag} className={styles.tag}>
                  #{tag}
                </div>
              ))}
            </div>
            {readingTime && (
              <div className={styles.readtime}>{readingTime}</div>
            )}
          </div>
          <ArticleHeader>
            <h1 className={styles.title}>{title}</h1>
            {author && <div className={styles.author}>{author}</div>}
            <ShareButtons
              url={getBlogPostUrl(slug)}
              title={title}
              quote={description}
            />
          </ArticleHeader>
        </div>

        <ArticleContent>{children}</ArticleContent>

        <ShareButtons
          url={getBlogPostUrl(slug)}
          title={title}
          quote={description}
        />
      </Article>
    </Container>
  );
};
